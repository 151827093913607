import Image from 'next/image'
import { classNames } from '@/utils/style'

interface NotFoundPageProps {
  returnTo: string
}

const NotFoundPage = (props: NotFoundPageProps) => {
  const handleClick = () => {
    window.location.href = props.returnTo
  }

  return (
    <div>
      <div className="mx-auto z-0 overflow-y-scroll py-60 w-full">
        <div className="text-center">
          <Image src="/icons/404.svg" alt="404" height={200} width={400} />
        </div>
        <div>
          <div className="min-h-full items-end p-4 text-center sm:items-center sm:p-0">
            <div className="font-semibold">
              404. It looks like there is an error...🔍
            </div>
            <div className="text-dark-500">
              Sorry, the page you are looking for is not found!
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                onClick={handleClick}
                type="button"
                className={classNames("justify-center rounded-full border border-transparent bg-primary-400 px-7 py-2 text-sm font-semibold text-white hover:bg-primary-500 focus:outline-none")}
              >
                Back to dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

NotFoundPage.defaultProps = {
  returnTo: '/landing'
}

export default NotFoundPage;
